<template>
  <div class="sos-view">
    <div class="sos-view__wrapper">
      <HeroTopSOS dark>
        <Section limiter>
          <Row class="hero-top-sos__top-lg-260 hero-top-sos__relative hero-top-sos__full-size">
            <Column class="grid-sm-full">
              <h1 class="hero-top-sos__title grid-md-7 grid-lg-7">
                {{ $t('info_view.title') }}</h1>
              <!--<p class="hero-top-sos__subtitle grid-md-6 grid-lg-6"></p>-->
              <Row class="-m-5"
                wrap>
                <Anchor v-for="anchor in anchors"
                  :key="anchor.id"
                  :params="{title: anchor.title, selector: anchor.selector, indent: 120}"/>
              </Row>
            </Column>
            <Column v-if="$laptop">
              <img class="hero-top-sos__mobile-app"
                :src="require('@/assets/images/mobile-app.png')">
            </Column>
          </Row>
        </Section>
      </HeroTopSOS>
      <Section v-if="show"
        limiter>
        <Row class="grid-sm-full"
          justify="between">
          <Column class="grid-sm-full grid-md-8 grid-lg-6">
            <h2 class="sos-view__title">{{ $t('info_view.placed.p_1') }}
              {{$city.declension ? $city.declension : $city.title}} {{ $t('info_view.placed.p_2') }}
            </h2>
          </Column>
          <Column v-if="!$laptop"
            class="grid-md-6 grid-lg-6"></Column>
        </Row>
      </Section>
      <Section v-if="show"
        :limiter="!$mobile"
        id="panels">
        <Row justify="between">
          <Column class="grid-sm-full grid-md-4 grid-lg-6">
            <List>
              <template slot="list-header">
                <span class="list__header-title">{{ $t('info_view.addresses') }}</span>
              </template>
              <template slot="list-content">
                <div class="list__item"
                  :class="place && place.id === item.id ? 'list__item-active' : ''"
                  v-on:click="showPanelOnMap(item)"
                  v-for="item in panels"
                  :key="item.id">
                  <Row justify="between"
                    :wrap="$mobile">
                    <Column class="list__item-meta">
                      <span class="list__item-meta-title">{{item.title}}</span>
                      <span class="list__item-meta-address">{{item.address}}</span>
                    </Column>
                    <Column v-if="$laptop"
                      class="list__item-image-holder">
                      <img v-if="item && item.image && item.image.length > 0"
                           class="list__item-image"
                        :src="item.image"
                        alt/>
                    </Column>
                  </Row>
                </div>
              </template>
            </List>
          </Column>
          <Column v-if="!$mobile"
            class="grid-md-4 grid-lg-6 relative">
            <OnMap :location="place && place.location || $city.location"
              :params="{sizeMap: {
                width: $laptop ? 'calc(50vw - 22px)' : '100%',
                height: $laptop ? '750px' : '300px'},}"
              :places="$panels.data"/>
          </Column>
        </Row>
      </Section>


      <Section limiter>
        <GuideAccordion>
          <GuideAccordionTab v-for="section in info.accordions"
            :key="section.id"
            :params="section"/>
        </GuideAccordion>
      </Section>
      <ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoView',
  data() {
    return {
      id: 0,
      place: null,
      show: false,
      style: { margin: '0' },
      location: [process.env.VUE_APP_DEFAULTH_LATITUDE, process.env.VUE_APP_DEFAULTH_LONGITUDE],
    };
  },
  methods: {
    showPanelOnMap(place) {
      this.place = place;
    },
    showPanels() {
      if (this?.panels?.length > 0) this.show = true;
    },
  },
  updated() {
    this.showPanels();
  },
  computed: {
    info() {
      return this.$meta.find((item) => item.slug === this.$city.slug)?.info;
    },
    anchors() {
      if (this.show) {
        return [{
          title: this.$i18n.t('anchors.panels'),
          selector: '#panels',
        }, {
          title: this.$i18n.t('anchors.code'),
          selector: '#city-code',
        }, {
          title: this.$i18n.t('anchors.emergency'),
          selector: '#emergency-services',
        }];
      }
      return [{
        title: this.$i18n.t('anchors.code'),
        selector: '#city-code',
      }, {
        title: this.$i18n.t('anchors.emergency'),
        selector: '#emergency-services',
      }];
    },
    allPanels() {
      return this?.$panels?.data;
    },
    panels() {
      return this?.allPanels?.filter((item) => item.city_id === this.$city.id);
    },
  },
  created() {
    this.$store.dispatch('GET_PANELS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        count: 100500,
        resolution: 'medium',
        city_id: this.$city.id,
        lang: this.$i18n.locale,
      },
    })
      .then(() => {
        this.showPanels();
      });
  },
};
</script>
